import { useMutation } from '@tanstack/react-query';
import type { MutateOptions } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { ExperienceEventFragment } from '@/features/events/fragments/ExperienceEventFragment';
import type { DecoratedMagicEvent } from '@/features/magic/types';
import { gqlClient } from '@/lib';
import type { CreateEventsInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  CreateEventsMutation,
  CreateEventsMutationVariables,
} from './useCopyEvents.generated';

type ExperienceLike = {
  id: string;
  timeZone: string;
};

const query = gql`
  ${ExperienceEventFragment}
  mutation CreateEvents($input: CreateEventsInput!) {
    createEvents(input: $input) {
      ...ExperienceEvent
    }
  }
`;

const createInput = (
  experience: ExperienceLike,
  events: DecoratedMagicEvent[]
): CreateEventsInput => {
  return {
    experienceId: experience.id,
    events: events.map((item) => {
      return {
        experienceId: experience.id,
        end: {
          dateOnly: item.isAllDay
            ? {
                date: item.endDate.toISODate(),
              }
            : undefined,
          dateTime: item.isAllDay
            ? undefined
            : {
                dateTime: item.endDate.toISO(),
                timeZone: experience.timeZone,
              },
        },
        start: {
          dateOnly: item.isAllDay
            ? {
                date: item.startDate.toISODate(),
              }
            : undefined,
          dateTime: item.isAllDay
            ? undefined
            : {
                dateTime: item.startDate.toISO(),
                timeZone: experience.timeZone,
              },
        },
        title: item.title,
        description: item.description,
        emoji: item.emoji,
        locationWithPlace: item.locationWithPlace,
        rrule: item.rrule,
      };
    }),
  };
};

export const useCopyEvents = () => {
  const { mutate, ...rest } = useMutation<
    CreateEventsMutation,
    QueryError,
    CreateEventsInput
  >({
    mutationFn: (input) => {
      return gqlClient.request<
        CreateEventsMutation,
        CreateEventsMutationVariables
      >(query, { input });
    },
  });

  const copyEvents = useCallback(
    (
      experience: ExperienceLike,
      events: DecoratedMagicEvent[],
      options?: MutateOptions<
        CreateEventsMutation,
        QueryError,
        CreateEventsInput
      >
    ) => {
      const input = createInput(experience, events);
      return mutate(input, options);
    },
    [mutate]
  );

  return {
    ...rest,
    copyEvents,
  };
};
