import {
  type MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import type { DecoratedMagicJob } from '@/features/magic/types';
import { gqlClient } from '@/lib';
import {
  ExperienceTypes,
  type CreateExperienceInput,
} from '@/types/gql.generated';
import { getLocalTimeZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import { createLibraryQueryKey } from '@/utils/queryKeys';
import { PageExperienceFragment } from '../fragments/PageExperience';
import { useCopyEvents } from './useCopyEvents';
import type {
  CreateExperienceMutation,
  CreateExperienceMutationVariables,
} from './useCreateExperience.generated';

type Options = Omit<
  MutateOptions<
    CreateExperienceMutation,
    QueryError,
    CreateExperienceMutationVariables
  >,
  'onError' | 'onSettled' | 'data'
> & {
  onError?: (error: QueryError) => void;
  onSettled?: () => void;
};

const query = gql`
  ${PageExperienceFragment}
  mutation CreateExperience($input: CreateExperienceInput!) {
    createExperience(input: $input) {
      ...PageExperience
    }
  }
`;

export const useCreateExperience = () => {
  const {
    mutate,
    isPending,
    error: createError,
    reset: resetCreate,
  } = useMutation<
    CreateExperienceMutation,
    QueryError,
    CreateExperienceMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        CreateExperienceMutation,
        CreateExperienceMutationVariables
      >(query, variables),
  });

  const queryClient = useQueryClient();

  const {
    copyEvents,
    isPending: isCopying,
    error: copyError,
    reset: resetCopy,
  } = useCopyEvents();

  const error = createError || copyError;

  const createExperienceFromMagicJob = useCallback(
    (
      magicJob: DecoratedMagicJob,
      options: Options = {},
      shareEvents: boolean = false
    ) => {
      const input: CreateExperienceInput = {
        type: ExperienceTypes.Page,
        timeZone: getLocalTimeZone(),
      };

      if (shareEvents) {
        input.magicJobId = magicJob.id;
      }

      mutate(
        { input },
        {
          ...options,
          onSuccess: (data, variables, context) => {
            if (shareEvents) {
              options.onSuccess?.(data, variables, context);
            } else {
              copyEvents(data.createExperience, magicJob.events, {
                ...options,
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: createLibraryQueryKey(),
                  });
                  options.onSuccess?.(data, variables, context);
                },
              });
            }
          },
        }
      );
    },
    [mutate, copyEvents, queryClient]
  );

  const createEmptyExperience = useCallback(
    (options: Options = {}) => {
      const input: CreateExperienceInput = {
        title: 'My new page',
        type: ExperienceTypes.Page,
        timeZone: getLocalTimeZone(),
      };
      return mutate(
        { input },
        {
          ...options,
          onSuccess: (...args) => {
            queryClient.invalidateQueries({
              queryKey: createLibraryQueryKey(),
            });
            options.onSuccess?.(...args);
          },
        }
      );
    },
    [mutate, queryClient]
  );

  const reset = useCallback(() => {
    resetCreate();
    resetCopy();
  }, [resetCreate, resetCopy]);

  return {
    createExperienceFromMagicJob,
    createEmptyExperience,
    isPending: isPending || isCopying,
    error,
    reset,
  };
};
